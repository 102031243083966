import { Container } from 'react-bootstrap'

const ServiceSegment = () => {
  return (
    <div id="service" className="text-white">
      <Container className="col-12 col-md-8 p-5 my-auto">
        <h2 className="text-center">SERWIS</h2>
        <hr />
        <h5>Z przyjemnością informujemy, że od lutego 2025 wracamy do prowadzenia własnego serwisu po kilku latach przerwy! Zapraszamy na przetestowanie naszego nowego Mechanika Łukasza! Wszystkie zapytania dotyczące terminów oraz wyceny serwisów prosimy kierować do sprzedawcó</h5>
        <br />
        <h5 className="text-center"><a href="tel:500606904" style={{color:"white",textDecoration:"none"}}>☎️ 
	  +48 501 222 108
	  ☎️</a></h5>
      </Container>
    </div>
  )
}

export default ServiceSegment
