import React, { useState, useEffect } from 'react'
import { Nav, Offcanvas, Row, Navbar } from 'react-bootstrap'
import ContactElement from './ContactElement'

const NavLinks = ({ white }) => {

  let [show, setShow] = useState(false)
  let [showMenu, setMenu] = useState(false)
  let [isMobile, setMobile] = useState(false)

  let handleShow = () => { setShow(true) }
  let handleHide = () => { setShow(false) }

  useEffect(() => {
    setMobile(window.innerWidth < 1200)
    window.addEventListener('resize', () => {
      setMobile(window.innerWidth < 1200)
    })
  }, [])

  let style = {
    height: isMobile ? "100vh" : "60vh"
  }

  return (
    <>
      { isMobile ? <><Navbar.Collapse>
        <Nav fill>
          <Nav.Link className={ white ? "text-white" : ""} href="/">STRONA GŁÓWNA</Nav.Link>
          <Nav.Link className={ white ? "text-white" : ""} href="/pojazdy">POJAZDY</Nav.Link>
          <Nav.Link className={ white ? "text-white" : ""} href="/odziez">ODZIEŻ</Nav.Link>
          <Nav.Link className={ white ? "text-white" : ""} href="/akcesoria">AKCESORIA</Nav.Link>
          <Nav.Link className={ white ? "text-white" : ""} href="/wypozyczalnia">WYPOŻYCZALNIA</Nav.Link>
          <Nav.Link className={ white ? "text-white" : ""} href="/#service">SERWIS</Nav.Link>
          <Nav.Link className={ white ? "text-white" : ""} href="/#parts">CZĘŚCI</Nav.Link>
          <Nav.Link className={ white ? "text-white" : ""} onClick={handleShow}>KONTAKT</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Toggle className="w-100 text-white" /></> :
      <Nav fill>
        <Nav.Link className={ white ? "text-white" : ""} href="/">STRONA GŁÓWNA</Nav.Link>
        <Nav.Link className={ white ? "text-white" : ""} href="/pojazdy">POJAZDY</Nav.Link>
        <Nav.Link className={ white ? "text-white" : ""} href="/odziez">ODZIEŻ</Nav.Link>
        <Nav.Link className={ white ? "text-white" : ""} href="/akcesoria">AKCESORIA</Nav.Link>
        <Nav.Link className={ white ? "text-white" : ""} href="/wypozyczalnia">WYPOŻYCZALNIA</Nav.Link>
        <Nav.Link className={ white ? "text-white" : ""} href="/#service">SERWIS</Nav.Link>
        <Nav.Link className={ white ? "text-white" : ""} href="/#parts">CZĘŚCI</Nav.Link>
        <Nav.Link className={ white ? "text-white" : ""} onClick={handleShow}>KONTAKT</Nav.Link>
      </Nav> }

      <Offcanvas show={show} onHide={handleHide} placement="top" style={style}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Kontakt</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="mx-3">
            <ContactElement title="Sklep i części" phone_numbers={["501 222 108", "75 75 18 011"]} />
            <ContactElement title="Serwis" phone_numbers={["501 222 108"]} />
            <ContactElement title="Wyprawy motocyklowe America West Ride" phone_numbers={["501 222 108"]} />
          </Row>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2515.6562152593906!2d15.816047315789058!3d50.91157697954164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470ee1ad63e7605d%3A0xbf847db4a5eb420c!2sMOTORCYCLE%20CENTER%20Grzegorz%20Baraniecki!5e0!3m2!1sen!2spl!4v1661981124709!5m2!1sen!2spl"
            loading="lazy"
            style={{ width:"100%", height:"30vh" }}
            className="mt-3">
          </iframe>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default NavLinks
